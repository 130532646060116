import { Link, useLocation } from "@remix-run/react";
import { Search } from "lucide-react";
import logo from "../images/logo.svg";

interface HeaderProps {
  searchButton?: boolean;
}

export function Header({ searchButton }: HeaderProps): JSX.Element {
  const { pathname } = useLocation();
  let center = false;
  if (pathname === "/") {
    center = true;
  }
  return (
    <header className="bg-primary-600">
      <div
        className={`ml-auto mr-auto flex h-16 max-w-4xl items-center px-5 pl-5 pr-5 md:h-24 ${
          center ? "md:justify-center" : "justify-start"
        }`}
      >
        <Link to="/" aria-current="page">
          <img
            alt=""
            className="relative h-6 w-[90px] shrink-0 overflow-hidden md:h-8 md:w-[121px]"
            loading="eager"
            src={logo}
          />
        </Link>
        {searchButton ? (
          <button
            className="flex cursor-pointer flex-row items-start justify-start bg-[transparent] p-0 [border:none]"
            type="button"
          >
            <div className="flex flex-row items-center justify-center gap-[8px] rounded-lg bg-primary-100 px-4 py-2.5">
              <Search className="h-4 w-4" />
            </div>
          </button>
        ) : null}
      </div>
    </header>
  );
}
