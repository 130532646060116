import type { LinksFunction } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { type ReactNode, useEffect } from "react";
import tailwindStylesheetUrl from "./app.css?url";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import NotFound from "./components/not-found";
import * as gtag from "./lib/gtag.client";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwindStylesheetUrl },
];

export function Layout({ children }: { children: ReactNode }): JSX.Element {
  const location = useLocation();
  const gaTrackingId = "GTM-TNB8CLWV";

  useEffect(() => {
    if (gaTrackingId.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="HelpSaúde" name="author" />
        <meta content="HelpSaúde (2024)" name="copyright" />
        <meta content="#274fad" name="theme-color" />
        <meta content="light" name="color-scheme" />
        <meta content="index, follow" name="robots" />
        <meta content="Helpsaúde" name="application-name" />
        <Meta />
        <Links />
      </head>
      <body>
        {!gaTrackingId ? null : (
          <>
            {process.env.NODE_ENV !== "development" && (
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9847031185780341"
                crossOrigin="anonymous"
              />
            )}
            <script
              async
              src={`https://metricas.helpsaude.com/gtm.js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              // biome-ignore lint/security/noDangerouslySetInnerHtml: <no other way to do this...>
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <Header />
        {children}
        <Footer />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App(): JSX.Element {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary(): JSX.Element {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <NotFound />;
}
